import { useState } from "react";
import DashboardMain from "../components/dashboard/DashboardMain";
import CliDataMain from "../components/cliData/CliDataMain";
import {
  Bars2Icon,
  DocumentMagnifyingGlassIcon,
  ListBulletIcon,
  PresentationChartBarIcon,
  RocketLaunchIcon,
} from "@heroicons/react/24/outline";

import CdrMain from "../components/cdr/CdrMain";
import UpdatesModal from "../components/updates/UpdatesModal";

const MenuItem = ({ icon, label, isSelected, toggle }) => {
  return (
    <li>
      <div
        className={`flex items-center px-2 py-6 cursor-pointer h-12 transition-all duration-200 ${
          isSelected
            ? "bg-gray-700 text-white"
            : "hover:bg-gray-700 hover:text-white"
        }`}
        onClick={toggle}
      >
        <span
          className={`text-sm flex gap-2 items-center ${
            isSelected ? "font-bold" : ""
          }`}
        >
          {icon}
          <span>{label}</span>
        </span>
      </div>
    </li>
  );
};

const DashboardPage = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState("mainDashboard");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);
  const toggleModal = () => setIsModalOpen(!isModalOpen);
  return (
    <div className="flex h-[95vh] w-full">
      <aside
        className={`h-full bg-gray-200 dark:bg-[#1D1E24] dark:text-white transition-all duration-300 ${
          isSidebarOpen ? "w-60" : "w-10"
        }`}
      >
        <div className="flex flex-col justify-between h-full">
          <div>
            <div className="flex justify-between items-center p-2">
              <span className={`text-xl font-semibold block`}></span>
              <Bars2Icon
                className="h-6 w-6 cursor-pointer"
                onClick={toggleSidebar}
              />
            </div>
            <ul>
              <MenuItem
                icon={<PresentationChartBarIcon className="h-5 w-5" />}
                label={isSidebarOpen ? "Main Dashboard" : ""}
                isSelected={selectedMenu === "mainDashboard"}
                toggle={() => setSelectedMenu("mainDashboard")}
              />
              <MenuItem
                icon={<DocumentMagnifyingGlassIcon className="h-5 w-5" />}
                label={isSidebarOpen ? "CLI Analyzer" : ""}
                isSelected={selectedMenu === "cliData"}
                toggle={() => setSelectedMenu("cliData")}
              />
              <MenuItem
                icon={<ListBulletIcon className="h-5 w-5" />}
                label={isSidebarOpen ? "CDR (Call Detail Records)" : ""}
                isSelected={selectedMenu === "cdr"}
                toggle={() => setSelectedMenu("cdr")}
              />
            </ul>
          </div>
          <div>
            <ul>
              <MenuItem
                icon={<RocketLaunchIcon className="h-5 w-5" />}
                label={
                  isSidebarOpen ? (
                    <div className="flex flex-col">
                      <span className="text-xs font-medium">Version 1.3.2</span>
                      <span className="text-xs text-gray-500">
                        View details and updates
                      </span>
                    </div>
                  ) : (
                    ""
                  )
                }
                isSelected={false}
                toggle={toggleModal}
              />
            </ul>
          </div>
        </div>
      </aside>
      <main className="flex-1 h-full">
        {selectedMenu === "mainDashboard" && <DashboardMain />}
        {selectedMenu === "cliData" && <CliDataMain />}
        {selectedMenu === "cdr" && <CdrMain />}
      </main>
      <UpdatesModal isOpen={isModalOpen} onClose={toggleModal} />
    </div>
  );
};

export default DashboardPage;
