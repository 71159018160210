import { useEffect, useState } from "react";
import UserService from "../../../services/users/users";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { FaUserEdit } from "react-icons/fa";
import CampaignService from "../../../services/campaigns/campaigns";
import { useDarkMode } from "../../../context/DarkModeContext";
import { useToast } from "../../../context/ToastContext";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const animatedComponents = makeAnimated();

const UserUpdate = ({ setShowEditUser, fetchUsers, user }) => {
  const mappedRoles = user.roles.map((role) => ({
    value: role.name,
    label: role.name,
  }));

  const hasAllCampaigns = user.campaignsAllowed.some(
    (campaign) => campaign.name === "ALL"
  );

  const mappedCampaigns = hasAllCampaigns
    ? [{ value: "ALL", label: "ALL" }]
    : user.campaignsAllowed.map((campaign) => ({
        value: campaign._id,
        label: (
          <span
            style={{ backgroundColor: campaign.color }}
            className="inline-flex items-center px-2 text-xs rounded-full font-medium mr-1 mb-1 text-white"
          >
            {campaign.name}
          </span>
        ),
      }));

  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const [showPasswordFields, setShowPasswordFields] = useState(false);

  const { showToast } = useToast();
  const [name, setName] = useState(user.name || "");
  const [surName1, setSurname1] = useState(user.surname1 || "");
  const [password, setPassword] = useState(null);
  const [repeatPassword, setRepeatPassword] = useState(null);
  const [email] = useState(user.email);
  const [roles, setRoles] = useState(mappedRoles);
  const [campaignsAllowed, setCampaignsAllowed] = useState(mappedCampaigns);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [campaignOptions, setCampaignOptions] = useState([]);
  const { darkMode: isDark } = useDarkMode();

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: "8px",
      boxShadow: state.isFocused
        ? isDark
          ? "0 0 0 2px white"
          : "0 0 0 2px #3b82f6"
        : "none",
      borderColor: state.isFocused
        ? isDark
          ? "white"
          : "#3b82f6"
        : isDark
        ? "#4b5563"
        : "#d1d5db",
      "&:hover": {
        borderColor: state.isFocused
          ? isDark
            ? "white"
            : "#3b82f6"
          : isDark
          ? "white"
          : "#d1d5db",
      },
      backgroundColor: isDark ? "#374151" : "white",
    }),
    multiValue: (styles) => ({
      ...styles,
      backgroundColor: isDark ? "#4b5563" : "#eff6ff",
    }),
    multiValueLabel: (styles) => ({
      ...styles,
      color: isDark ? "white" : "#1e40af",
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      color: isDark ? "white" : "#1e40af",
      "&:hover": {
        backgroundColor: isDark ? "#6b7280" : "#1e40af",
        color: "white",
      },
    }),
    menu: (styles) => ({
      ...styles,
      backgroundColor: isDark ? "#374151" : "white",
    }),
    option: (styles, { isDisabled }) => ({
      ...styles,
      backgroundColor: isDisabled
        ? isDark
          ? "#4b5563"
          : "#e5e7eb"
        : "transparent",
      color: isDisabled
        ? isDark
          ? "#9ca3af"
          : "#6b7280"
        : isDark
        ? "white"
        : "black",
      cursor: isDisabled ? "not-allowed" : "pointer",
    }),
  };

  const roleOptions = [
    { value: "User.Read", label: "User.Read" },
    { value: "User.ReadWrite", label: "User.ReadWrite" },
    { value: "Campaign.Read", label: "Campaign.Read" },
    { value: "Numbering.Read", label: "Numbering.Read" },
    { value: "Stats.Read", label: "Stats.Read" },
    { value: "CDR.Read", label: "CDR.Read" },
  ];

  useEffect(() => {
    CampaignService.listCampaigns()
      .then(
        ({
          data: {
            data: { campaigns },
          },
        }) => {
          const campaignsToPush = [{ value: "ALL", label: "ALL" }];
          for (let campaign of campaigns) {
            campaignsToPush.push({
              value: campaign._id,
              label: (
                <span
                  style={{ backgroundColor: campaign.color }}
                  className={`inline-flex items-center px-2 text-xs rounded-full font-medium mr-1 mb-1 text-white`}
                >
                  {campaign.name}
                </span>
              ),
            });
          }
          setCampaignOptions(campaignsToPush);
        }
      )
      .catch((error) => {});
  }, []);

  const handleSave = () => {
    setError(null);
    setIsLoading(true);

    const data = {};

    if (name !== user.name) data.name = name;
    if (surName1 !== user.surname1) data.surname1 = surName1;
    if (roles !== user.roles) data.roles = roles.map((role) => role.value);
    if (campaignsAllowed !== user.campaignsAllowed) {
      data.campaignsAllowed = campaignsAllowed.map(
        (campaign) => campaign.value
      );
    }

    if (showPasswordFields && password !== null) {
      data.password = password;
      data.repeatPassword = repeatPassword;
    }

    UserService.updateUser({ userId: user._id, data })
      .then((response) => {
        if (
          response.status === 200 ||
          (response.status >= 200 && response.status < 300)
        ) {
          showToast("success", "User updated successfully!");

          fetchUsers();
          setShowEditUser(false);
          setIsLoading(false);
        } else {
          throw new Error("Unexpected response status");
        }
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          setError(error.response.data.message);
        } else {
          setError("ERROR: please contact administrator support@astroline.com");
        }
        setIsLoading(false);
      });
  };

  const isFormValid = () => {
    return name && surName1 && roles.length > 0 && campaignsAllowed.length > 0;
  };

  const handleCampaignChange = (selected) => {
    if (selected.some((option) => option.value === "ALL")) {
      setCampaignsAllowed([{ value: "ALL", label: "ALL" }]);
    } else {
      setCampaignsAllowed(selected);
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50 dark:text-white">
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 max-w-2xl w-full space-y-6">
        <h2 className="text-xl  flex items-center gap-2">
          <FaUserEdit />
          Updating user - {email}
        </h2>

        <div className="flex gap-4">
          <div className="w-1/2">
            <label className="text-sm  block dark:text-white">Name</label>
            <input
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full p-2 border rounded-lg dark:bg-gray-700 dark:text-white"
            />
          </div>
          <div className="w-1/2">
            <label className="text-sm  block dark:text-white">Last Name</label>
            <input
              value={surName1}
              onChange={(e) => setSurname1(e.target.value)}
              className="w-full p-2 border rounded-lg dark:bg-gray-700 dark:text-white"
            />
          </div>
        </div>
        <div className="mb-4">
          <label className="flex items-center">
            <input
              type="checkbox"
              checked={showPasswordFields}
              onChange={(e) => setShowPasswordFields(e.target.checked)}
              className="mr-2"
            />
            Change password
          </label>
        </div>

        {showPasswordFields && (
          <div className="flex gap-4">
            <div className="w-1/2 relative">
              <label className="text-sm  block dark:text-white">Password</label>
              <input
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full p-2 border rounded-lg dark:bg-gray-700 dark:text-white"
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="absolute right-2 top-2/3 transform -translate-y-1/2 text-gray-500 dark:text-gray-300 flex items-center justify-center h-full"
              >
                <span className="text-md">
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
              </button>
            </div>

            <div className="w-1/2 relative">
              <label className="text-sm  block dark:text-white">
                Repeat Password
              </label>
              <input
                type={showRepeatPassword ? "text" : "password"}
                value={repeatPassword}
                onChange={(e) => setRepeatPassword(e.target.value)}
                className="w-full p-2 border rounded-lg dark:bg-gray-700 dark:text-white"
              />
              <button
                type="button"
                onClick={() => setShowRepeatPassword(!showRepeatPassword)}
                className="absolute right-2 top-2/3 transform -translate-y-1/2 text-gray-500 dark:text-gray-300 flex items-center justify-center h-full"
              >
                <span className="text-md">
                  {showRepeatPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
              </button>
            </div>
          </div>
        )}

        <div>
          <label className="text-sm  block dark:text-white">Roles</label>
          <Select
            isMulti
            components={animatedComponents}
            options={roleOptions}
            value={roles}
            onChange={setRoles}
            styles={customStyles}
            closeMenuOnSelect={false}
          />
        </div>

        <div>
          <label className="text-sm  block dark:text-white">
            Campaigns Allowed
          </label>
          <Select
            isMulti
            components={animatedComponents}
            options={campaignOptions.map((option) => ({
              ...option,
              isDisabled:
                campaignsAllowed.some((selected) => selected.value === "ALL") &&
                option.value !== "ALL",
            }))}
            value={campaignsAllowed}
            onChange={handleCampaignChange}
            styles={customStyles}
            closeMenuOnSelect={false}
          />
        </div>

        {error && <p className="text-red-500">{error}</p>}

        <div className="flex justify-end gap-4">
          <button
            onClick={() => setShowEditUser(false)}
            className="px-4 py-2 bg-gray-500 text-white rounded-lg"
          >
            Cancel
          </button>
          <button
            onClick={handleSave}
            className={`px-4 py-2 text-white rounded-lg ${
              isFormValid() ? "bg-green-500" : "bg-gray-400"
            }`}
            disabled={!isFormValid() || isLoading}
          >
            {isLoading ? "Updating..." : "Update"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserUpdate;
