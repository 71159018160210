import React, { useState } from "react";
import Lottie from "react-lottie-player";
import thankyouAnimation from "../../assets/images/thankyou.json";
import CustomerService from "../../services/customers/customers";

const Feedback = ({ setShowFeedback }) => {
  const [feedback, setFeedback] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState(null);

  const handleReport = () => {
    setIsLoading(true);
    setResponse(null);

    CustomerService.feedback({ feedback })
      .then(() => {
        setResponse({
          status: "success",
          message:
            "Thank you for your feedback! We deeply value your insights.",
        });
      })
      .catch(() => {
        setResponse({
          status: "error",
          message:
            "An error occurred while submitting your feedback. Please try again later or contact support.",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50"
    >
      <div className="bg-white dark:bg-gray-800 dark:text-white rounded-lg shadow-lg p-6 max-w-2xl w-full transition-all duration-300">
        {response?.status === "success" ? (
          <div className="flex flex-col items-center text-center">
            <Lottie
              play
              loop={false}
              animationData={thankyouAnimation}
              className="h-52 w-52 mb-4"
            />
            <h2 className="text-xl font-bold text-gray-800 dark:text-white">
              Thank You!
            </h2>
            <p className="mt-2 text-sm font-medium text-gray-600 dark:text-gray-400">
              Your feedback has been received successfully. ASR Vooster is
              thrilled to receive your insights and continually improve to serve
              you better. Thank you for helping us grow! 🌟
            </p>
            <button
              onClick={() => setShowFeedback(false)}
              className="mt-6 px-4 py-2 bg-blue-600 hover:bg-blue-500 text-white text-sm rounded-lg transition-colors"
            >
              Close
            </button>
          </div>
        ) : (
          <>
            <h2 className="text-2xl mb-4 font-bold text-center">
              Share Your Feedback
            </h2>
            <p className="text-sm text-center text-gray-600 dark:text-gray-400 mb-6">
              ASR Vooster is designed to continuously enhance the user
              experience. Feedback, ideas, and concerns are invaluable in
              shaping improvements and delivering even better performance
            </p>
            <div className="my-6 mb-10">
              <textarea
                id="description"
                rows="10"
                className="text-sm w-full mt-2 p-3 border rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-400"
                placeholder="Type your thoughts here and help shape the future of ASR Vooster."
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
              />
            </div>
            {response && response.status === "error" && (
              <div className="text-center text-sm font-semibold p-3 mb-6 rounded-lg bg-red-100 text-red-700">
                {response.message}
              </div>
            )}
            <div className="flex justify-end gap-4 text-sm">
              <button
                onClick={() => setShowFeedback(false)}
                className="px-4 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-400 transition-colors"
              >
                Close
              </button>
              <button
                onClick={handleReport}
                className={`px-4 py-2 text-white rounded-lg ${
                  feedback
                    ? "bg-blue-600 hover:bg-blue-500"
                    : "bg-gray-400 cursor-not-allowed"
                } transition-colors`}
                disabled={isLoading || !feedback.trim()}
              >
                {isLoading ? "Submitting..." : "Submit Feedback"}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Feedback;
