import React, { useEffect, useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import CampaignService from "../../services/campaigns/campaigns";
import { EuiSuperDatePicker, EuiThemeProvider } from "@elastic/eui";
import { useDarkMode } from "../../context/DarkModeContext";

const animatedComponents = makeAnimated();

const FiltersBar = ({
  filters,
  setFilters,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}) => {
  const [campaignOptions, setCampaignOptions] = useState([]);
  const { darkMode: isDark } = useDarkMode();
  const [selectedCampaign, setSelectedCampaign] = useState(
    filters.campaign || null
  );

  const [sourceConverted, setSourceConverted] = useState(
    filters.sourceConverted !== undefined ? filters.sourceConverted : true
  );

  const [selectedCode, setSelectedCode] = useState(filters.code || null);
  const [searchNumber, setSearchNumber] = useState(filters.searchNumber || "");
  const [dateRange, setDateRange] = useState({
    start: startDate,
    end: endDate,
  });
  const [isResetMode, setIsResetMode] = useState(false);

  const [initialFilters] = useState({
    campaign: null,
    code: null,
    sourceConverted: true,
    searchNumber: "",
    start: startDate,
    end: endDate,
  });

  const [appliedFilters, setAppliedFilters] = useState({
    campaign: filters.campaign || null,
    sourceConverted: filters.sourceConverted || null,
    code: filters.code || null,
    searchNumber: filters.searchNumber || "",
    start: startDate,
    end: endDate,
  });

  const hasChanges =
    selectedCampaign !== appliedFilters.campaign ||
    selectedCode !== appliedFilters.code ||
    searchNumber !== appliedFilters.searchNumber ||
    dateRange.start !== appliedFilters.start ||
    sourceConverted !== appliedFilters.sourceConverted ||
    dateRange.end !== appliedFilters.end;

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: "4px",
      borderColor: state.isFocused
        ? isDark
          ? "white"
          : "#3b82f6"
        : isDark
        ? "#4b5563"
        : "#d1d5db",
      "&:hover": {
        borderColor: state.isFocused
          ? isDark
            ? "white"
            : "#3b82f6"
          : isDark
          ? "#4b5563"
          : "#d1d5db",
      },
      backgroundColor: isDark ? "#374151" : "white",
      color: isDark ? "white" : "black",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: isDark ? "#9ca3af" : "#6b7280",
    }),
    input: (styles) => ({
      ...styles,
      color: isDark ? "white" : "black",
    }),
    menu: (styles) => ({
      ...styles,
      backgroundColor: isDark ? "#1F2937" : "white",
      zIndex: 100,
      borderRadius: "8px",
      border: `1px solid ${isDark ? "white" : "#3b82f6"}`,
      overflowY: "auto",
      padding: "4px",
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => ({
      ...styles,
      backgroundColor: isDisabled
        ? isDark
          ? "#4b5563"
          : "#e5e7eb"
        : isSelected
        ? isDark
          ? "#1f2937"
          : "#2563eb"
        : isFocused
        ? isDark
          ? "#1f2937"
          : "#e2e8f0"
        : "transparent",
      color: isDisabled
        ? isDark
          ? "#9ca3af"
          : "#6b7280"
        : isDark
        ? "white"
        : "black",
      cursor: isDisabled ? "not-allowed" : "pointer",
      "&:hover": {
        backgroundColor: isDisabled
          ? undefined
          : isDark
          ? "#1f2937"
          : "#93c5fd",
        color: isDisabled ? undefined : "white",
      },
      borderBottom: `1px solid ${isDark ? "#4b5563" : "#e5e7eb"}`,
      ":last-child": {
        borderBottom: "none",
      },
      padding: "8px",
    }),
  };

  const customStylesCodes = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: "4px",
      borderColor: state.isFocused
        ? isDark
          ? "white"
          : "#3b82f6"
        : isDark
        ? "#4b5563"
        : "#d1d5db",
      "&:hover": {
        borderColor: state.isFocused
          ? isDark
            ? "white"
            : "#3b82f6"
          : isDark
          ? "#4b5563"
          : "#d1d5db",
      },
      backgroundColor: isDark ? "#374151" : "white",
      color: isDark ? "white" : "black",

      display: "flex",
    }),
    singleValue: (styles) => ({
      ...styles,
      display: "flex",
      alignItems: "center",
      color: isDark ? "white" : "black",
      fontSize: "0.875rem",
      fontWeight: "500",
      "& > div": {
        // Oculta cualquier descripción en el valor seleccionado
        display: "none",
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: isDark ? "#9ca3af" : "#6b7280",
    }),
    input: (styles) => ({
      ...styles,
      color: isDark ? "white" : "black",
    }),
    menu: (styles) => ({
      ...styles,
      backgroundColor: isDark ? "#1F2937" : "white",
      zIndex: 100,
      borderRadius: "4px",
      border: `1px solid ${isDark ? "white" : "#3b82f6"}`,
      padding: "2px",
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 9999,
      maxHeight: "90vh",
      overflowY: "visible",
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => ({
      ...styles,
      backgroundColor: isDisabled
        ? isDark
          ? "#4b5563"
          : "#e5e7eb"
        : isSelected
        ? isDark
          ? "#1f2937"
          : "#2563eb"
        : isFocused
        ? isDark
          ? "#1f2937"
          : "#e2e8f0"
        : "transparent",
      color: isDisabled
        ? isDark
          ? "#9ca3af"
          : "#6b7280"
        : isDark
        ? "white"
        : "black",
      cursor: isDisabled ? "not-allowed" : "pointer",
      padding: "4px 8px",
      borderBottom: `1px solid ${isDark ? "#4b5563" : "#e5e7eb"}`,
      ":last-child": {
        borderBottom: "none",
      },
      "&:hover": {
        backgroundColor: isDisabled
          ? undefined
          : isDark
          ? "#1f2937"
          : "#93c5fd",
        color: isDisabled ? undefined : "white",
      },
      display: "flex",
      justifyContent: "space-between",
    }),
    groupHeading: (styles) => ({
      ...styles,
      color: isDark ? "#9ca3af" : "#6b7280",
      fontSize: "10px",
      textTransform: "uppercase",
      margin: "4px 0",
      borderBottom: `1px solid ${isDark ? "#4b5563" : "#e5e7eb"}`,
      paddingBottom: "2px",
    }),
  };

  useEffect(() => {
    CampaignService.listCampaigns()
      .then((response) => {
        const campaigns = response.data.data.campaigns.map((campaign) => ({
          value: campaign._id,
          label: (
            <span
              style={{ backgroundColor: campaign.color }}
              className="inline-flex items-center px-2 text-xs rounded-full font-medium text-white"
            >
              {campaign.name}
            </span>
          ),
          searchValue: campaign.name.toLowerCase(),
          color: campaign.color,
        }));
        setCampaignOptions(campaigns);
      })
      .catch((error) => console.error("Error loading campaigns:", error));
  }, []);

  useEffect(() => {
    if (hasChanges) {
      setIsResetMode(false);
    }
  }, [hasChanges]);

  const handleButtonClick = () => {
    if (isResetMode) {
      setSelectedCampaign(initialFilters.campaign);
      setSelectedCode(initialFilters.code);
      setSearchNumber(initialFilters.searchNumber);
      setDateRange({ start: initialFilters.start, end: initialFilters.end });
      setStartDate(initialFilters.start);
      setEndDate(initialFilters.end);
      setFilters(initialFilters);
      setSourceConverted(initialFilters.sourceConverted);
      setIsResetMode(false);
    } else if (hasChanges) {
      const newFilters = {
        campaign: selectedCampaign,
        searchNumber,
        start: dateRange.start,
        end: dateRange.end,
        code: selectedCode,
        sourceConverted,
      };
      setFilters(newFilters);
      setAppliedFilters(newFilters);
      setIsResetMode(true);
    }
  };
  const codes = [
    { value: 200, label: "200 - OK", description: "Successful call" },
    { value: 487, label: "487 - CANCEL", description: "Call was canceled" },
    {
      value: 480,
      label: "480 - NO ANSWER",
      description: "No response from the user",
    },
    { value: 486, label: "486 - BUSY", description: "The line is busy" },
    {
      value: 404,
      label: "404 - OFFLINE / NOT FOUND",
      description: "Destination does not exist",
    },
    { value: 4, label: "4xx - OTHER", description: "Other 4xx error" },
    { value: 5, label: "5xx - OTHER", description: "Other 5xx error" },
    { value: 6, label: "6xx - OTHER", description: "Other 6xx error" },
  ];

  const customFilterOption = (option, inputValue) => {
    return option.data.searchValue.includes(inputValue.toLowerCase());
  };

  const renderCodeDot = (code) => {
    if (code < 300 && code > 100) return "bg-green-500";
    if (code < 500 && code > 100) return "bg-yellow-500";
    return "bg-red-500";
  };
  const codeOptions = codes.map(({ value, label, description }) => ({
    value,
    label: (
      <div className="flex flex-col text-xs">
        <div className="flex items-center">
          <span
            className={`h-1.5 w-1.5 mr-2 rounded-full flex-shrink-0 ${renderCodeDot(
              value
            )}`}
          ></span>
          {label}
        </div>
        <span className="text-xs text-gray-500">{description}</span>
      </div>
    ),
    singleLabel: label, // Agregamos un campo extra solo para el valor seleccionado
  }));

  return (
    <div className="h-[7vh] p-4 flex justify-between items-center">
      <div className="flex gap-4 items-center">
        <div className="flex flex-col">
          <label className="text-sm font-semibold text-gray-700 dark:text-gray-300 mb-1 flex items-center">
            Campaigns
            <span className="ml-1 text-gray-500 cursor-pointer relative group">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="h-5 w-5 text-gray-700 dark:text-gray-300"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                />
              </svg>

              <div
                className="absolute hidden group-hover:block text-xs rounded-md py-1 px-2 w-64 mt-2 left-0 transform translate-x-5 -translate-y-1/2 z-10"
                style={{
                  background: "rgba(0, 0, 0, 0.7)",
                  backdropFilter: "blur(8px)",
                  color: "white",
                }}
              >
                Select a campaign to filter calls by campaign. You can clear
                your selection to include all campaigns.
              </div>
            </span>
          </label>
          <Select
            components={animatedComponents}
            options={campaignOptions}
            className="text-sm w-96"
            isClearable={true}
            styles={customStyles}
            menuPortalTarget={document.body}
            onChange={(option) =>
              setSelectedCampaign(option ? option.value : null)
            }
            filterOption={customFilterOption}
            value={
              selectedCampaign
                ? campaignOptions.find(
                    (option) => option.value === selectedCampaign
                  )
                : null
            }
            placeholder="Select a campaign..."
          />
        </div>
        <div className="flex flex-col ">
          <label className="text-sm font-semibold text-gray-700 dark:text-gray-300 mb-1 flex items-center">
            Filter by Source/Destination
            <span className="ml-1 text-gray-500 cursor-pointer relative group">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="h-5 w-5 text-gray-700 dark:text-gray-300"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                />
              </svg>
              <div
                className="absolute hidden group-hover:block text-xs rounded-md py-1 px-2 w-64 mt-2 left-0 transform translate-x-5 -translate-y-1/2 z-10"
                style={{
                  background: "rgba(0, 0, 0, 0.7)",
                  backdropFilter: "blur(8px)",
                  color: "white",
                }}
              >
                Search calls by source or destination number. You can use
                wildcards (e.g., '52*' for numbers starting with 52) or specific
                numbers.
              </div>
            </span>
          </label>
          <input
            type="text"
            className="border border-gray-300 dark:border-gray-600 rounded px-3 py-2 w-64 text-sm dark:bg-gray-700 dark:text-white"
            placeholder="e.g., 52* or 525512345678"
            value={searchNumber}
            onChange={(e) => setSearchNumber(e.target.value)}
          />
        </div>
        <div className="flex flex-col">
          <label className="text-sm font-semibold text-gray-700 dark:text-gray-300 mb-1 flex items-center">
            Call Code
          </label>
          <Select
            components={animatedComponents}
            options={codeOptions}
            className="text-sm w-64"
            isClearable={true}
            styles={customStylesCodes}
            menuPortalTarget={document.body}
            onChange={(option) => setSelectedCode(option ? option.value : null)}
            value={
              selectedCode
                ? codeOptions.find((option) => option.value === selectedCode)
                : null
            }
            getOptionLabel={(e) => e.label} // Muestra descripción y código en el menú
            formatOptionLabel={(option, { context }) =>
              context === "value" ? option.singleLabel : option.label
            } // Usa `singleLabel` solo para la selección actual
            placeholder="Select a call code"
          />
        </div>
        <div className="flex flex-col">
          <label className="text-sm font-semibold text-gray-700 dark:text-gray-300 mb-1 flex items-center">
            Source Converted
            <span className="ml-1 text-gray-500 cursor-pointer relative group">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="h-5 w-5 text-gray-700 dark:text-gray-300"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                />
              </svg>
              <div
                className="absolute hidden group-hover:block text-xs rounded-md py-1 px-2 w-64 mt-2 left-0 transform translate-x-5 -translate-y-1/2 z-10"
                style={{
                  background: "rgba(0, 0, 0, 0.7)",
                  backdropFilter: "blur(8px)",
                  color: "white",
                }}
              >
                Toggle to filter calls based on whether the source has been
                converted.
              </div>
            </span>
          </label>
          <div className="flex items-center gap-4">
            <label className="flex items-center gap-2 cursor-pointer">
              <input
                type="radio"
                name="sourceConverted"
                value="true"
                checked={sourceConverted === true}
                onChange={() => setSourceConverted(true)}
                className="w-3 h-3 text-blue-600 focus:ring-blue-500 border-gray-300 rounded-full dark:bg-gray-700 dark:border-gray-600"
              />
              <span className="text-sm text-gray-700 dark:text-gray-300">
                Yes
              </span>
            </label>

            <label className="flex items-center gap-2 cursor-pointer">
              <input
                type="radio"
                name="sourceConverted"
                value="false"
                checked={sourceConverted === false}
                onChange={() => setSourceConverted(false)}
                className="w-3 h-3 text-blue-600 focus:ring-blue-500 border-gray-300 rounded-full dark:bg-gray-700 dark:border-gray-600"
              />
              <span className="text-sm text-gray-700 dark:text-gray-300">
                No
              </span>
            </label>
          </div>
        </div>
      </div>
      <div className="flex items-end content-end">
        <EuiThemeProvider colorMode={isDark ? "dark" : "light"}>
          <EuiSuperDatePicker
            showUpdateButton={false}
            start={dateRange.start}
            end={dateRange.end}
            onTimeChange={({ start, end }) => {
              setStartDate(start);
              setEndDate(end);
              setDateRange({ start, end });
            }}
            className="h-[38px]"
          />
        </EuiThemeProvider>
        <button
          className={`ml-4 px-4 py-2 h-[37px] rounded font-medium text-sm ${
            hasChanges || isResetMode
              ? isResetMode
                ? "bg-red-600 text-white hover:bg-red-700"
                : "bg-blue-600 text-white hover:bg-blue-700"
              : "bg-gray-300 text-gray-600 cursor-not-allowed"
          }`}
          onClick={handleButtonClick}
          disabled={!hasChanges && !isResetMode}
        >
          {isResetMode ? "Reset Filters" : "Apply Filters"}
        </button>
      </div>
    </div>
  );
};

export default FiltersBar;
