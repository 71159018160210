import React, { useState, useEffect } from "react";
import CdrService from "../../services/cdr/cdr";
import { EuiModal, EuiSuperDatePicker } from "@elastic/eui";
import FiltersCampaignsOnly from "../shared/FiltersCampaignsOnly";
import { FaEye, FaFilter, FaRegCheckCircle } from "react-icons/fa";
import Pagination from "../shared/Pagination";
import { useDarkMode } from "../../context/DarkModeContext";
import Flag from "../shared/Flag";
import PermissionsKo from "../shared/PermissionsKo";
import GenesysLogo from "../../assets/images/GenesysLogo.svg";
import AsteriskLogo from "../../assets/images/AsteriskLogo.png";
import AnotherLogo from "../../assets/images/WorldLogo.svg";
import SignalWireLogo from "../../assets/images/SignalWireLogo.webp";
import DigitalVirgoLogo from "../../assets/images/DigitalVirgoLogo.webp";
import successIcon from "../../assets/images/Success.svg";
import errorIcon from "../../assets/images/Error.svg";
import transferIcon from "../../assets/images/Transfer.svg";
import { TbTransferVertical } from "react-icons/tb";
import { GiConfirmed } from "react-icons/gi";
import { RxDotsHorizontal, RxDotsVertical } from "react-icons/rx";
import AsrVoosterLogo from "../../assets/images/LogoWhite.svg";

import FiltersBar from "./CdrFilters";
import PaginationCdr from "../shared/PaginationCdr";
import { VscError } from "react-icons/vsc";
const CdrMain = () => {
  const { darkMode } = useDarkMode();
  const [isLoading, setLoading] = useState(true);
  const [pit, setPit] = useState(null);
  const [cdrData, setCdrData] = useState([]);
  const [startDate, setStartDate] = useState("now-1d");
  const [endDate, setEndDate] = useState("now");
  const [filters, setFilters] = useState({
    campaign: null,
    code: null,
    sourceConverted: true,
  });
  const [showFilters, setShowFilters] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(13);
  const [totalItems, setTotalItems] = useState(0);
  const [isUnauthorized, setIsUnauthorized] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [callSelected, setCallSelected] = useState(null);

  const renderActionsColumn = (call) => (
    <button
      onClick={() => {
        setCallSelected(call);
        setShowModal(true);
      }}
      className="p-2 dark:text-white rounded"
    >
      <div className="relative group">
        <FaEye />
        <div
          className="absolute hidden group-hover:block text-xs rounded-md py-1 px-2 w-20 mt-2 left-[-500%] transform"
          style={{
            background: "rgba(0, 0, 0, 0.7)",
            backdropFilter: "blur(8px)",
            color: "white",
          }}
        >
          Call details
        </div>
      </div>
    </button>
  );

  const renderCodeDot = (status) => {
    const numericStatus = parseInt(status);

    if (numericStatus === 200) {
      return `bg-green-600`;
    } else if (
      numericStatus >= 500 ||
      numericStatus === 403 ||
      numericStatus === 401
    ) {
      return `bg-red-600`;
    } else if (numericStatus >= 400 && numericStatus < 500) {
      return `bg-yellow-600`;
    } else {
      return `bg-gray-600`;
    }
  };

  const getImageForUA = (ua) => {
    if (!ua) return null;

    const lowerCaseUA = ua.toLowerCase();

    if (lowerCaseUA.includes("genesys")) {
      return GenesysLogo;
    }
    if (lowerCaseUA.includes("asterisk")) {
      return AsteriskLogo;
    }
    if (lowerCaseUA.includes("anotherkeyword")) {
      return AnotherLogo;
    }
    if (lowerCaseUA.includes("signalwire")) {
      return SignalWireLogo;
    }
    if (lowerCaseUA.includes("dvbs")) {
      return DigitalVirgoLogo;
    }

    return AnotherLogo;
  };

  useEffect(() => {
    CdrService.pit()
      .then(({ data: { pit } }) => {
        setPit(pit);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          setIsUnauthorized(true);
        }
        setLoading(false);
      });
  }, []);

  const fetchCdrData = () => {
    if (!pit) return;

    const requestData = {
      pit,
      startDate,
      endDate,
      page: currentPage,
      itemsPerPage,
      filters,
    };

    setLoading(true);
    CdrService.cdr(requestData)
      .then(({ data: { message } }) => {
        setCdrData(message.calls);
        setTotalItems(message.total);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          setIsUnauthorized(true);
        }
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchCdrData();
  }, [pit, filters, currentPage, itemsPerPage]);

  useEffect(() => {
    const themeLight = "/white.css";
    const themeDark = "/dark.css";

    const loadTheme = (themePath) => {
      const head = document.head;
      const link = document.createElement("link");

      link.type = "text/css";
      link.rel = "stylesheet";
      link.href = themePath;

      const existingLink = document.getElementById("theme-style");
      if (existingLink) {
        head.removeChild(existingLink);
      }

      link.id = "theme-style";
      head.appendChild(link);
    };

    if (darkMode) {
      loadTheme(themeDark);
    } else {
      loadTheme(themeLight);
    }

    return () => {
      const existingLink = document.getElementById("theme-style");
      if (existingLink) {
        document.head.removeChild(existingLink);
      }
    };
  }, [darkMode]);

  const onTimeChange = ({ start, end }) => {
    setStartDate(start);
    setEndDate(end);
  };

  function formatDuration(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  }

  return (
    <div className="w-full h-full p-2">
      {isUnauthorized ? (
        <PermissionsKo />
      ) : (
        <>
          <FiltersBar
            filters={filters}
            setFilters={setFilters}
            startDate={startDate} //
            endDate={endDate}
            onTimeChange={onTimeChange}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
          />

          <div className="p-4 h-[86vh] flex flex-col">
            <div className="flex flex-col h-full">
              <div className="overflow-x-auto flex-grow">
                <div className="border rounded-lg overflow-hidden">
                  <table
                    className="table-fixed min-w-full dark:text-white border-collapse"
                    style={{ tableLayout: "fixed" }}
                  >
                    <thead className="border-b bg-slate-200 dark:bg-[#1D1E24] sticky top-0 z-10">
                      <tr>
                        <th
                          className="dark:text-white text-gray-black font-medium text-left px-3 py-2"
                          style={{ width: "15%" }}
                        >
                          Start Time
                        </th>
                        <th
                          className="dark:text-white text-gray-black font-medium text-left px-3 py-2"
                          style={{ width: "17%" }}
                        >
                          Campaign
                        </th>
                        <th
                          className="dark:text-white text-gray-black font-medium text-left px-3 py-2"
                          style={{ width: "17%" }}
                        >
                          <div className="flex gap-1 items-center">
                            Source
                            <div className="relative group">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="h-5 w-5 text-gray-700 dark:text-gray-300 cursor-pointer"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                                />
                              </svg>
                              <div
                                className="absolute hidden group-hover:block text-xs rounded-md py-1 px-2 w-64 mt-2 left-1/2 transform -translate-x-1/2"
                                style={{
                                  background: "rgba(0, 0, 0, 0.7)",
                                  backdropFilter: "blur(8px)",
                                  color: "white",
                                }}
                              >
                                {" "}
                                Transformed CLI: Phone Number displayed in the
                                destination, rotated according to the strategy
                                and pool applied to the campaign.
                              </div>
                            </div>
                          </div>
                        </th>
                        <th
                          className="dark:text-white text-gray-black font-medium text-left px-3 py-2"
                          style={{ width: "17%" }}
                        >
                          Destination
                        </th>
                        <th
                          className="dark:text-white text-gray-black font-medium text-left px-3 py-2"
                          style={{ width: "10%" }}
                        >
                          Call Code
                        </th>
                        <th
                          className="dark:text-white text-gray-black font-medium text-left px-3 py-2"
                          style={{ width: "8%" }}
                        >
                          Duration
                        </th>
                        <th
                          className="dark:text-white text-gray-black font-medium text-left px-3 py-2"
                          style={{ width: "14%" }}
                        >
                          Source IP Address
                        </th>
                        <th
                          className="dark:text-white text-gray-black font-medium text-left px-3 py-2"
                          style={{ width: "2%" }}
                        ></th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ? (
                        Array.from({ length: 13 }).map((_, index) => (
                          <tr key={index} className="animate-pulse">
                            <td className="py-2 px-3" colSpan="8">
                              <div className="h-9 bg-gray-300 rounded"></div>
                            </td>
                          </tr>
                        ))
                      ) : cdrData.length === 0 ? (
                        <tr>
                          <td colSpan="8" className="text-left py-8">
                            <div className="flex flex-col items-center">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-12 h-12 text-gray-400 dark:text-gray-300"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M15.75 3.75 18 6m0 0 2.25 2.25M18 6l2.25-2.25M18 6l-2.25 2.25m1.5 13.5c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 0 1 4.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 0 0-.38 1.21 12.035 12.035 0 0 0 7.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 0 1 1.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 0 1-2.25 2.25h-2.25Z"
                                />
                              </svg>
                              <p className="mt-4 text-gray-400 dark:text-gray-300">
                                No calls with the specified criteria have been
                                found.
                              </p>
                            </div>
                          </td>
                        </tr>
                      ) : (
                        cdrData.map((call, index) => (
                          <tr
                            key={index}
                            className="text-sm border-t border-gray-300 dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-800 transition"
                          >
                            <td className="py-2 px-3 text-left ">
                              {/* Start Time */}
                              {(() => {
                                const timestamp =
                                  call.startTime *
                                  (String(call.startTime).length === 10
                                    ? 1000
                                    : 1);
                                const userTimeZone =
                                  Intl.DateTimeFormat().resolvedOptions()
                                    .timeZone;
                                return new Date(timestamp).toLocaleString(
                                  undefined,
                                  {
                                    timeZone: userTimeZone,
                                    year: "numeric",
                                    month: "long",
                                    day: "numeric",
                                    hour: "numeric",
                                    minute: "numeric",
                                    second: "numeric",
                                  }
                                );
                              })()}
                            </td>
                            <td className="py-2 px-3 text-left">
                              {/* Campaign */}
                              <div className="flex flex-wrap justify-left items-center relative group">
                                {call.campaign && call.campaign.name ? (
                                  <span
                                    style={{
                                      backgroundColor: call.campaign?.color,
                                    }}
                                    className="inline-flex items-center dark:text-white px-2 text-xs rounded-full font-medium mr-1 mb-1 text-white"
                                  >
                                    {call.campaign?.name}
                                  </span>
                                ) : (
                                  <div className="flex items-center gap-2 bg-gray-600 text-white px-2 py-1 rounded-full cursor-pointer relative group">
                                    {/* CLI Received */}
                                    <span className="text-xs font-medium">
                                      {call.cliReceived || "Unknown CLI"}
                                    </span>
                                    {/* Warning Icon */}
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      strokeWidth={2}
                                      stroke="yellow"
                                      className="w-4 h-4"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M12 9v2m0 4h.01m-6.938 4h13.856c1.054 0 1.636-1.14 1.14-2.054l-6.928-13.856c-.518-1.036-2.022-1.036-2.54 0L3.636 16.946c-.497.914.086 2.054 1.14 2.054z"
                                      />
                                    </svg>
                                    {/* Tooltip */}
                                    <div className="absolute top-full left-1/2 transform -translate-x-1/2 mt-2 bg-gray-700 text-white text-xs rounded px-3 py-2 shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 w-64 z-10 pointer-events-none">
                                      This means that the call we are receiving
                                      from this CLI is not associated with any
                                      campaign and may be failing. Please check
                                      the configuration of the corresponding
                                      campaign.
                                    </div>
                                  </div>
                                )}
                              </div>
                            </td>

                            <td className="py-2 px-3 text-left ">
                              {/* Source */}
                              <div className="flex items-center justify-left">
                                <Flag
                                  code={call.srcIsoCode}
                                  className="mr-1 flex-shrink-0"
                                />
                                <div>
                                  {call?.src}
                                  <div className="text-gray-400 text-xs text-left ">
                                    {call.srcZone}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="py-2 px-3">
                              {/* Destination */}
                              <div className="flex items-center justify-left">
                                <Flag
                                  code={call.dstIsoCode}
                                  className="mr-1 flex-shrink-0"
                                />
                                <div>
                                  {call?.dst}
                                  <div className="text-gray-400 text-xs text-left">
                                    {call.dstZone}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="py-2 px-3 text-left items-center">
                              <div className="flex items-center justify-left">
                                <span
                                  className={`h-1.5 w-1.5 mr-2 rounded-full flex-shrink-0 ${renderCodeDot(
                                    call.callCode
                                  )}`}
                                ></span>
                                {call.callCode} - {call.callStatus}
                              </div>
                            </td>
                            <td className="py-2 px-3 text-left ">
                              {/* Duration */}
                              {formatDuration(call.duration)}
                            </td>
                            <td className="py-2 px-3 text-left">
                              {/* IP - UA */}
                              <div className="flex items-center justify-left">
                                {(() => {
                                  const imageSrc = getImageForUA(call?.ua);
                                  if (imageSrc) {
                                    return (
                                      <img
                                        src={imageSrc}
                                        alt="User Agent Logo"
                                        className="mr-2 h-6 w-6 flex-shrink-0"
                                      />
                                    );
                                  }
                                  return null;
                                })()}
                                <div className="text-left">
                                  {call.sourceIp}
                                  <div className="text-gray-400 text-xs text-left">
                                    {call.ua}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="py-2 px-3 text-left">
                              {renderActionsColumn(call)}
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="mt-0">
                <PaginationCdr
                  totalItems={totalItems}
                  currentPage={currentPage}
                  itemsPerPage={itemsPerPage}
                  setCurrentPage={setCurrentPage}
                  onItemsPerPageChange={setItemsPerPage}
                />
              </div>
            </div>
            {showModal && callSelected && (
              <div
                className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
                onClick={() => setShowModal(false)}
              >
                <div
                  className="bg-white dark:bg-gray-800 rounded-lg shadow-2xl overflow-auto w-[70vw] md:w-[50vw] max-w-full transform transition-all duration-300"
                  onClick={(e) => e.stopPropagation()}
                >
                  {/* Encabezado */}
                  <div className="flex justify-between items-center px-6 py-4 bg-gray-100 dark:bg-gray-700 border-b border-gray-300 dark:border-gray-600">
                    <h2 className="text-xl font-semibold text-gray-800 dark:text-white">
                      Call Details
                    </h2>
                    <button
                      onClick={() => setShowModal(false)}
                      className="text-gray-500 dark:text-gray-300 hover:text-gray-700 dark:hover:text-white transition"
                    >
                      ✕
                    </button>
                  </div>

                  {/* Contenido */}
                  <div className="p-6">
                    {/* Representación gráfica del flujo de la llamada */}
                    <div className="flex flex-col gap-2 items-center">
                      {/* CLI Received */}
                      <div className="flex gap-8 bg-gray-200 rounded-lg py-1 px-3 items-center">
                        <div>
                          <p className="text-xs font-semibold text-gray-600 ">
                            CLI Received
                          </p>
                          <p className="text-gray-900 text-sm">
                            {callSelected.cliReceived}
                          </p>
                        </div>
                        <div className="flex items-center text-sm">
                          {(() => {
                            const imageSrc = getImageForUA(callSelected?.ua);
                            if (imageSrc) {
                              return (
                                <img
                                  src={imageSrc}
                                  alt="User Agent Logo"
                                  className="mr-2 h-6 w-6 flex-shrink-0"
                                />
                              );
                            }
                            return null;
                          })()}
                          <div className="text-left">
                            {callSelected.sourceIp}
                            <div className="text-gray-600 text-xs">
                              {callSelected.ua}
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Flecha y Transformación */}
                      <div className="flex flex-col gap-2 items-center">
                        <RxDotsVertical className="h-8 w-8 dark:text-white" />
                        <div className="flex items-center cursor-pointer relative group">
                          <TbTransferVertical className="h-8 w-8 dark:text-white" />
                        </div>

                        {/* Badge de Vooster con badge adicional a la derecha */}
                        <div className="relative flex items-center">
                          {/* Badge principal de Vooster */}
                          <span
                            className={`rounded-lg py-1 px-3 flex flex-col text-white font-semibold text-xs ${
                              callSelected.cliConverted
                                ? "bg-green-700"
                                : "bg-red-500"
                            }`}
                          >
                            <img
                              src={AsrVoosterLogo}
                              alt="voosterLogo"
                              className="w-26 h-5 mb-1"
                            />
                            {callSelected.cliConverted
                              ? "CLI Transformed successfully"
                              : "Error transforming CLI"}
                          </span>

                          {/* Badge adicional para campaña */}
                          {callSelected.campaign &&
                          callSelected.campaign.name ? (
                            <div className="flex gap-2 absolute top-1/2 -translate-y-1/2 left-full ml-2 items-center">
                              <RxDotsHorizontal className="h-8 w-8 dark:text-white" />

                              {/* Badge principal de campaña */}
                              <span
                                style={{
                                  backgroundColor:
                                    callSelected.campaign.color || "#ef4444",
                                }}
                                className="relative inline-block dark:text-white px-3 py-2 text-xs rounded-lg font-medium text-white whitespace-nowrap"
                              >
                                {callSelected.cliConverted
                                  ? callSelected.campaign.name
                                  : "Please contact support@astroline.com"}

                                {/* Badge adicional para la regla de conversión */}
                                {callSelected.cliConversionStrategy && (
                                  <span className="absolute -top-5 left-1/2 -translate-x-1/2 bg-gray-600 text-white px-3 py-1 text-xs rounded-lg font-medium whitespace-nowrap">
                                    {callSelected.cliConversionStrategy ===
                                    "randomcli"
                                      ? "Random CLI"
                                      : "Random CLI with GEO"}
                                  </span>
                                )}
                              </span>
                            </div>
                          ) : (
                            <div className="flex gap-2 absolute top-1/2 -translate-y-1/2 left-full ml-2 items-center">
                              <RxDotsHorizontal className="h-8 w-8 dark:text-white" />
                              <span
                                style={{
                                  backgroundColor:
                                    callSelected.campaign.color || "#ef4444",
                                }}
                                className="inline-block dark:text-white px-3 py-2 text-xs rounded-lg font-medium text-white whitespace-nowrap"
                              >
                                Without campaing associated
                              </span>
                            </div>
                          )}
                        </div>

                        <RxDotsVertical className="h-8 w-8 dark:text-white" />
                      </div>

                      {/* Información de origen y destino */}
                      <div className="flex flex-col items-center bg-gray-200 rounded-lg py-1 px-3">
                        <div className="flex justify-between gap-16">
                          {/* Source */}
                          <div className="">
                            <p className="text-xs font-medium text-gray-700 ">
                              Source
                            </p>
                            <div className="items-center justify-center text-sm">
                              {callSelected.src}
                              <div className="flex text-xs text-gray-500 -ml-2">
                                <Flag code={callSelected.srcIsoCode} />{" "}
                                {callSelected.srcZone}
                              </div>
                            </div>
                          </div>

                          {/* Destino */}
                          <div className="text-right">
                            <p className="text-xs font-medium text-gray-700 ">
                              Destination
                            </p>
                            <div className="items-center justify-center text-sm">
                              {callSelected.dst}
                              <div className="flex text-xs text-gray-500 -mr-2">
                                {callSelected.dstZone}
                                <Flag code={callSelected.dstIsoCode} />{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <RxDotsVertical className="h-8 w-8 dark:text-white" />

                      <div className="flex flex-col bg-gray-200 rounded-lg py-1 px-3">
                        {/* Primera fila: ID y Código de llamada */}
                        <div className="flex justify-between w-full gap-4">
                          {/* ID a la izquierda */}
                          <div className="text-left">
                            <p className="text-xs font-medium text-gray-700">
                              Call ID
                            </p>
                            <p className="text-gray-900 text-sm">
                              {callSelected.id}
                            </p>
                          </div>
                          {/* Código de llamada a la derecha */}
                          <div className="text-right">
                            <p className="text-xs font-medium text-gray-700">
                              Call Code
                            </p>
                            <div className="flex items-center justify-end text-sm text-gray-900">
                              <span
                                className={`h-1.5 w-1.5 mr-2 rounded-full flex-shrink-0 ${renderCodeDot(
                                  callSelected.callCode
                                )}`}
                              ></span>
                              {callSelected.callCode} -{" "}
                              {callSelected.callStatus}
                            </div>
                          </div>
                        </div>

                        {/* Segunda fila: Hora de inicio y Duración */}
                        <div className="flex justify-between w-full gap-4 mt-4">
                          {/* Hora de inicio a la izquierda */}
                          <div className="text-left">
                            <p className="text-xs font-medium text-gray-700">
                              Start Time
                            </p>
                            <p className="text-gray-900 text-sm">
                              {(() => {
                                const timestamp =
                                  callSelected.startTime *
                                  (String(callSelected.startTime).length === 10
                                    ? 1000
                                    : 1);
                                const userTimeZone =
                                  Intl.DateTimeFormat().resolvedOptions()
                                    .timeZone;
                                return new Date(timestamp).toLocaleString(
                                  undefined,
                                  {
                                    timeZone: userTimeZone,
                                    year: "numeric",
                                    month: "long",
                                    day: "numeric",
                                    hour: "numeric",
                                    minute: "numeric",
                                    second: "numeric",
                                  }
                                );
                              })()}
                            </p>
                          </div>
                          {/* Duración a la derecha */}
                          <div className="text-right">
                            <p className="text-xs font-medium text-gray-700">
                              Duration
                            </p>
                            <p className="text-gray-900 text-sm">
                              {formatDuration(callSelected.duration)}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Pie del modal */}
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default CdrMain;
