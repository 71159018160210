import React from "react";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

const Pagination = ({
  totalItems,
  itemsPerPage,
  currentPage,
  setCurrentPage,
}) => {
  const totalPages = Math.floor(totalItems / itemsPerPage);
  const startItem = (currentPage - 1) * itemsPerPage + 1;
  const endItem = Math.min(startItem + itemsPerPage - 1, totalItems);

  const goToPage = (page) => {
    const pageNumber = Math.max(1, Math.min(page, totalPages));
    setCurrentPage(pageNumber);
  };

  const getPaginationGroup = () => {
    let start = Math.max(currentPage - 1, 1);
    let end = Math.min(currentPage + 1, totalPages);

    if (currentPage === 1) {
      start = 1;
      end = Math.min(3, totalPages);
    } else if (currentPage === totalPages) {
      start = Math.max(totalPages - 2, 1);
      end = totalPages;
    }

    const range = [];
    for (let i = start; i <= end; i++) {
      range.push(i);
    }

    return range;
  };

  const paginationGroup = getPaginationGroup();

  return (
    <div className="flex items-center justify-between w-full">
      <div className="flex flex-1 items-center justify-between">
        <div>
          <p className="text-sm text-gray-700 dark:text-white">
            Showing
            <span className="font-medium font-sans "> {startItem} </span>
            to
            <span className="font-medium font-sans"> {endItem} </span>
            of
            <span className="font-medium font-sans"> {totalItems} </span>
            results
          </p>
        </div>
        <div>
          <nav
            className="isolate inline-flex -space-x-px rounded-md shadow-sm"
            aria-label="Pagination"
          >
            <button
              onClick={() => goToPage(currentPage - 1)}
              className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 dark:text-white ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
              disabled={currentPage === 1}
            >
              <FaAngleLeft className="h-5 w-5" />
            </button>
            {currentPage > 2 && (
              <>
                <button
                  onClick={() => goToPage(1)}
                  className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 dark:text-white ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                >
                  1
                </button>
                {currentPage > 3 && (
                  <span className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 dark:text-white ring-inset ring-gray-300">
                    ...
                  </span>
                )}
              </>
            )}
            {paginationGroup.map((number) => (
              <button
                key={number}
                onClick={() => goToPage(number)}
                className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${
                  number === currentPage
                    ? "bg-customBlackBackground text-white"
                    : "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 dark:text-white dark:hover:text-black"
                } focus:z-20 focus:outline-offset-0`}
              >
                {number}
              </button>
            ))}
            {currentPage < totalPages - 1 && (
              <>
                {currentPage < totalPages - 2 && (
                  <span className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 dark:text-white ring-inset ring-gray-300">
                    ...
                  </span>
                )}
                <button
                  onClick={() => goToPage(totalPages)}
                  className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 dark:text-white ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                >
                  {totalPages}
                </button>
              </>
            )}
            <button
              onClick={() => goToPage(currentPage + 1)}
              className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 dark:text-white ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
              disabled={currentPage === totalPages}
            >
              <FaAngleRight className="h-5 w-5" />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
