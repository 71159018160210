import React, { useState } from "react";
import updates from "./updates.json";
import { useDarkMode } from "../../context/DarkModeContext";
import Feedback from "./Feedback";

const UpdatesModal = ({ isOpen, onClose }) => {
  const getMediaSrc = (mediaName) => `/assets/images/updates/${mediaName}`;
  const [showFeedback, setShowFeedback] = useState(false);
  const { darkMode } = useDarkMode();

  return (
    <div
      className={`fixed inset-0 z-50 bg-black bg-opacity-80 transition-opacity ${
        isOpen ? "opacity-100" : "opacity-0 pointer-events-none"
      }`}
      onClick={onClose}
    >
      <div
        className={`fixed right-0 top-0 h-screen bg-white dark:bg-gray-800 dark:text-white shadow-lg w-2/5 transform transition-transform ${
          isOpen ? "translate-x-0" : "translate-x-full"
        }`}
        onClick={(e) => e.stopPropagation()}
      >
        {/* Close Button */}
        <div
          className="absolute left-[-50px] top-1/2 transform -translate-y-1/2 bg-gray-300 hover:bg-gray-400 dark:bg-gray-700 dark:hover:bg-gray-600 rounded-full w-10 h-10 flex items-center justify-center cursor-pointer shadow-md transition-transform duration-200 hover:scale-110"
          onClick={onClose}
        >
          <span className="text-xl font-bold text-gray-700 dark:text-gray-300">
            &times;
          </span>
        </div>

        {/* Header */}
        <div className="p-6 border-b border-gray-300 dark:border-gray-700 flex justify-between items-center">
          <div>
            <h2 className="text-2xl font-bold">Latest Updates</h2>
            <p className="text-sm text-gray-500 dark:text-gray-400">
              Stay up-to-date with the latest releases, fixes, and features.
            </p>
          </div>
          {/* Suggestion Icon */}
          <div
            className="flex items-center cursor-pointer text-gray-600 dark:text-gray-300 hover:text-blue-500 dark:hover:text-blue-400 transition-colors space-x-4"
            onClick={() => setShowFeedback(true)}
          >
            <div className="flex flex-col">
              <span className="text-md font-medium text-end">
                Suggestion Box
              </span>
              <span className="text-xs">Click here to share your feedback</span>
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 3.75H6.912a2.25 2.25 0 0 0-2.15 1.588L2.35 13.177a2.25 2.25 0 0 0-.1.661V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 0 0-2.15-1.588H15M2.25 13.5h3.86a2.25 2.25 0 0 1 2.012 1.244l.256.512a2.25 2.25 0 0 0 2.013 1.244h3.218a2.25 2.25 0 0 0 2.013-1.244l.256-.512a2.25 2.25 0 0 1 2.013-1.244h3.859M12 3v8.25m0 0-3-3m3 3 3-3"
              />
            </svg>
          </div>
        </div>

        {/* Body */}
        <div className="p-6 overflow-y-auto h-[calc(100vh-100px)]">
          {updates?.map((update, index) => (
            <div
              key={index}
              className="mb-6 p-4 bg-gray-100 dark:bg-gray-900 rounded-lg border border-gray-300 dark:border-gray-700"
            >
              {/* Header Section */}
              <div className="flex justify-between items-center mb-4">
                <div className="flex gap-2">
                  {update.type && (
                    <span
                      className={`text-xs py-1 px-2 rounded-xl ${
                        update.type === "New Release"
                          ? "bg-green-200 text-green-700"
                          : update.type === "Bugfix"
                          ? "bg-yellow-200 text-yellow-700"
                          : "bg-blue-500 text-white"
                      }`}
                    >
                      {update.type}{" "}
                    </span>
                  )}

                  <span
                    className={`text-xs py-1 px-2 rounded-xl text-gray-600 bg-gray-200`}
                  >
                    {update.version}
                  </span>
                </div>
                <span className="text-xs text-gray-500 dark:text-gray-400">
                  {update.date}
                </span>
              </div>
              {/* Title */}
              <h3 className="text-lg font-bold mb-2">{update.title}</h3>
              {/* Categorized Items */}
              {update?.items &&
                Object.entries(update?.items)?.map(([category, items]) => (
                  <div key={category} className="mb-4">
                    <h4 className="font-semibold text-gray-700 dark:text-gray-300 capitalize">
                      {category}
                    </h4>
                    <ul className="list-disc pl-6 text-gray-700 dark:text-gray-400">
                      {items?.map((item, idx) => (
                        <li key={idx}>{item}</li>
                      ))}
                    </ul>
                  </div>
                ))}
              {/* Media */}
              {update.media && (
                <div className="my-4 flex justify-center items-center">
                  {update.media.type === "image" && (
                    <img
                      src={getMediaSrc(update.media.name)}
                      alt={update.media.alt}
                      style={{
                        width: "400px",
                        margin: 20,
                        height: "200px",
                        // objectFit: "cover", // Mantener proporciones
                      }}
                      className={`rounded-lg  ${
                        !darkMode && !update.type && "bg-gray-600 px-2"
                      }`}
                    />
                  )}
                  {update.media.type === "video" && (
                    <video
                      controls
                      src={getMediaSrc(update.media.name)}
                      className="rounded-lg shadow-md"
                    />
                  )}
                </div>
              )}
              {/* Footer Section */}
              {update.message && (
                <div className="flex items-center space-x-1 mt-4 text-gray-500 dark:text-gray-400">
                  <img
                    src="/assets/images/updates/Astroline.png"
                    alt="Astroline Team Avatar"
                    className="w-6 h-6 rounded-full"
                  />
                  <p className="text-sm italic">{update.message}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      {showFeedback && <Feedback setShowFeedback={setShowFeedback} />}
    </div>
  );
};

export default UpdatesModal;
