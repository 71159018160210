import http from "../../http-common";

const pit = () => {
  return http.get(`/api/v1/pit`);
};

const cdr = (data) => {
  return http.post(`/api/v1/cdr`, data);
};

const CdrService = {
  pit,
  cdr,
};

export default CdrService;
