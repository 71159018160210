import React, { createContext, useState, useContext, useEffect } from "react";

const DarkModeContext = createContext();

export const useDarkMode = () => useContext(DarkModeContext);

export const DarkModeProvider = ({ children }) => {
  const [darkMode, setDarkMode] = useState(() => {
    const alreadyDark = localStorage.getItem("darkMode");
    return alreadyDark === "true"; // Inicializa directamente con el valor guardado
  });

  useEffect(() => {
    localStorage.setItem("darkMode", darkMode.toString()); // Sincroniza con localStorage
  }, [darkMode]);

  return (
    <DarkModeContext.Provider value={{ darkMode, setDarkMode }}>
      {children}
    </DarkModeContext.Provider>
  );
};
