import React, { useEffect, useState } from "react";
import Lottie from "react-lottie-player";
import lampAnimation from "../../assets/images/darkLightAnimation.json";

const LampAnimation = ({ theme, isVisible, onAnimationEnd }) => {
  const [blurClass, setBlurClass] = useState("");

  useEffect(() => {
    if (isVisible) {
      setBlurClass(theme === "dark" ? "dark" : "light");
    }
  }, [theme, isVisible]);

  return (
    <div
      className={`lamp-animation-overlay ${
        isVisible ? "visible" : ""
      } ${blurClass}`}
      onTransitionEnd={() => !isVisible && onAnimationEnd()}
    >
      <Lottie
        loop={false}
        animationData={lampAnimation}
        play={isVisible}
        direction={theme === "dark" ? 1 : -1}
        style={{ height: 500, width: 500 }}
      />
    </div>
  );
};

export default LampAnimation;
